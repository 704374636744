// Colours
$white: #fff;

// bootstrap
$primary: #ff655b;
$secondary: #008bf8;
$light: #eeeeee;

// Custom variables
$navbar-height: 72px;
