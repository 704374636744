.benefits {
  .benefits-container {
    padding-top: 4rem;
    padding-bottom: 4rem;

    .col-md-4 {
      margin-bottom: 1.5rem;
      text-align: center;

      h2 {
        font-weight: 400;
      }

      p {
        margin-right: 0.75rem;
        margin-left: 0.75rem;
      }
    }
  }
}
